<template>
  <section class="section is-paddingless">
    <Navbar />
    <div v-show="!unsubscribed && singleUnsub.length === 0" class="container">
      <br><br><br>
      <br><br><br>
      <div class="has-text-centered">
        <h1 class="title is-spaced" v-text="$t('unsubscribe')" />
        <h2 class="subtitle" v-text="$t('unsubscribe_p')" />
        <div class="columns is-centered is-mobile">
          <div class="column has-text-left is-centered is-narrow">

            <b-checkbox v-model="subscription.newsletter">
              Newsletter
            </b-checkbox>
            <br>
			<b-checkbox v-model="subscription.onboarding">
              Onboarding
            </b-checkbox>
			<br>
			<b-checkbox v-model="subscription.steady_gains">
              Steady Gains
            </b-checkbox>

            <br>
            <div v-if="is_registered">
              <hr class="mt-10 mb-10" />
              <p class="has-text-weight-medium is-size-18 mb-10">{{$t('unsubscribe_app')}}</p>
              <b-checkbox v-model="subscription.liquidation_warnings">
                Liquidation Warnings
              </b-checkbox>
              <br>
              <b-checkbox v-model="subscription.limit_orders">
                Limit Orders
              </b-checkbox>
			  <br>
              <b-checkbox v-model="subscription.trading_competition">
                Trading Competition
              </b-checkbox>
            </div>
          </div>
        </div>
        <h3 v-text="$t('unsubscribe_p2')" />
        <br>
        <div class="columns is-centered mb-0">
          <button
            style="margin-right: 5px;"
            type="submit"
            class="button is-large input-button is-primary"
            :class="{ 'is-loading': loadingUpdate }"
            @click="unsubscribe"
          >
            <span class="is-size-6 is-fs-18">{{ $t("update_button") }}</span>
          </button>
          <div class="is-hidden-desktop">
            <br>
          </div>
          <button
            style="margin-left: 5px;"
            type="submit"
            class="button is-large input-button"
            :class="{ 'is-loading': loading }"
            @click="unsubscribeAll"
          >
            <span class="is-size-6 is-fs-18">{{
              $t("unsubscribe_button")
            }}</span>
          </button>
        </div>
        <div class="is-fs-14 mt-10" style="marginTop:-3px">
          <b-field
            :class="{ 'bad-input': recaptchaHasError }"
            :message="recaptchaError"
          />
          <p v-html="$t('recaptcha_usage')" />
        </div>
      </div>
      <div>
        <vue-recaptcha
          ref="recaptcha"
          size="invisible"
          :sitekey="recaptchaSiteKey"
          :loadRecaptchaScript="true"
          @verify="onCaptchaVerified"
          @error="onCaptchaError"
          @expired="onCaptchaExpired"
          @render="onCaptchaLoaded"
        />
      </div>
    </div>

    <div v-show="!unsubscribed && singleUnsub.length > 0" class="container">
      <br><br><br>
      <div class="loader custom-loader" />
      <div class="has-text-centered">
        <h1 class="title is-spaced" v-text="$t('unsubscribe_loading')" />

        <br>
        <div class="is-fs-14 mt-10" style="marginTop:-3px">
          <b-field
            :class="{ 'bad-input': recaptchaHasError }"
            :message="recaptchaError"
          />
          <p v-html="$t('recaptcha_usage')" />
        </div>
      </div>
    </div>
    <div v-show="unsubscribed" class="container">
      <br><br><br>
      <figure class="center">
        <img src="@/assets/img/checkmark.svg" alt="Checkmark">
      </figure>
      <br>
      <div class="has-text-centered">
        <h1
          v-if="singleUnsub.length > 0"
          class="title is-spaced"
          v-text="
            $t('unsubscribe_success_single') + firstLetterUppercase(singleUnsub)
          "
        />
        <h1
          v-if="singleUnsub.length === 0"
          class="title is-spaced"
          v-text="$t('unsubscribe_success')"
        />
        <h2 class="subtitle" v-text="$t('unsubscribe_success_p')" />
        <hr class="page-break">
        <br>
        <div class="has-text-centered social-links transition-faster">
            <a
							href="https://twitter.com/morpher_io"
							target="_blank"
							rel="noopener noreferrer"
							alt="Morpher Twitter"
							title="Twitter"
						>
							<b-icon icon="fab fa-twitter" pack="fab" class="is-size-5" />
						</a>
						<a
							href="https://discord.gg/CBxuAhPSCj"
							target="_blank"
							rel="noopener noreferrer"
							alt="Morpher Discord"
							title="Discord"
						>
							<b-icon icon="fab fa-discord" pack="fab" class="is-size-5" />
						</a>
						<a
							href="https://t.me/morpher_io"
							target="_blank"
							rel="noopener noreferrer"
							alt="Morpher Telegram"
							title="Telegram"
						>
							<b-icon icon="fab fa-telegram-plane" pack="fab" class="is-size-5" />
						</a>
						<a
							href="https://vk.com/morpher"
							target="_blank"
							rel="noopener noreferrer"
							alt="Morpher VK"
							title="VK"
						>
							<b-icon icon="fab fa-vk" pack="fab" class="is-size-5" />
						</a>
						<a
							href="https://www.linkedin.com/company/morpher"
							target="_blank"
							rel="noopener noreferrer"
							alt="Morpher LinkedIn"
							title="LinkedIn"
						>
							<b-icon icon="fab fa-linkedin-in" pack="fab" class="is-size-5" />
					  </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/*eslint no-console: 2*/
import Navbar from "@/components/partials/Navbar.vue";
import VueRecaptcha from "vue-recaptcha";
import emailService from "../services/Email";
import { i18n } from "../plugins/i18n";


export default {
	name: "Subscribed",
	components: {
		Navbar,
		"vue-recaptcha": VueRecaptcha,
	},
	data() {
		return {
			recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA,
			recaptchaToken: "",
			loadingUpdate: false,
			loading: false,
			singleUnsub: "",
			unsubscribed: false,
			recaptchaHasError: false,
			recaptchaLoaded: false,
			recaptchaError: "",
			is_registered: false,
			subscription: {
				onboarding: false,
				newsletter: false,
				liquidation_warnings: false,
				limit_orders: false,
				trading_competition: false,
				steady_gains: false
			},
		};
	},
	async mounted() {
		document.dispatchEvent(new Event("x-app-rendered"));
		if (!this.$route.query.id) this.$router.push("/");

		await emailService.getSubscription(this);

		// Check if unsubscribing from newsletter only
		let query = this.$route.query.unsub;
		if (query) {
			if (query =='product_updates' || query =='account_info' || query =='airdrop') {
				query = 'newsletter' 
			}
			this.subscription[query] = false;
			this.singleUnsub = query;

			this.executeRecaptcha();
		}
	},
	methods: {
		// execute the recaptcha if the recaptcha api is loaded. if it is not loaded thenretry in 1 second.
		executeRecaptcha() {
			if (this.recaptchaLoaded) {
				this.$refs.recaptcha.execute();
			} else {
				setTimeout(() => {
					this.executeRecaptcha();
				}, 1000);
			}
		},
		// unsubscrivbe from all lists
		unsubscribeAll() {
			Object.keys(this.subscription).map(
				(key) => (this.subscription[key] = false)
			);
			this.loading = true;
			this.executeRecaptcha();
		},
		// submit subscription preferences
		unsubscribe() {
			this.loadingUpdate = true;
			this.executeRecaptcha();
		},
		// submit the unsubscribe once the recaptcha is verified
		onCaptchaVerified(response) {
			this.recaptchaToken = response;
			emailService.unsubscribeSubmit(this);
		},
		onCaptchaExpired() {
			this.$refs.recaptcha.reset();
			this.$buefy.snackbar.open({
				position: "is-bottom-left",
				message: i18n.t("error_captcha_unsub"),
			});
		},
		// if an error occurred with recaptcha then display it
		onCaptchaError() {
			this.loading = false;
			this.recaptchaHasError = true;
			this.recaptchaError = "Error loading recaptcha api";
		},
		// set a flag once the recaptcha api is loaded so that we know if we can execute the api.
		onCaptchaLoaded(id) {
			this.recaptchaLoaded = true;
		},
	},
	head() {
		return {
			title: { inner: "Manage Email Subscription" },
			meta: [{ hid: "robots", name: "robots", content: "noindex" }],
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mt-10 {
	margin-top: 10px !important;
}
.page-break {
	width: 50%;
	margin: 0 auto;
}
.mb-0 {
	margin-bottom: 0px;
}
.custom-loader {
	margin: 30px auto;
	width: 60px;
	height: 60px;
	border-width: 5px;
	border-color: transparent transparent #00c386 #00c386;
}
.bad-input {
	color: #fc6404 !important;
}

.bad-input input.input {
	border-color: #fc6404 !important;
}

.social-links a {
  color: #4c4c4c;
  margin-right: 1rem;
}
.social-links a:hover {
  color: #228cdb;
}
</style>
